<template>
    <v-footer>
        <v-container
            fluid
        >
            <v-row
                justify="center"
            >
                <v-col
                    :class="{'smallSize': mobileSize}"
                >
                    <b>{{ name }}</b> | {{ email }} | {{ phone }}
                    <br>

                    <template v-if="link">
                        <a                        
                            :href="link"
                            target="_blank"
                        >
                            {{ $t('privacy-policy') }}
                        </a>
                    </template>
                </v-col>
            </v-row>
        </v-container>
    </v-footer>
</template>
<script>
export default {
    data(){
        return {
            name: null,
            email: null,
            phone: null,
            mobileSize: false
        }
    },
    computed: {
        link() {
            
            return process.env.VUE_APP_COMPANY_POLICY_LINK
        }
    },
    beforeMount(){
        this.name = this.clientName
        this.email = this.clientEmail
        this.phone = this.clientPhone
        this.windowSize()
    },
    created() {
        window.addEventListener('resize', this.windowSize)
    },
    methods:{
        windowSize(){
            if(window.innerWidth < 400){
                this.mobileSize = true

            }else{
                this.mobileSize = false        
            }
        }
    }
}
</script>
<style>
.smallSize{
    font-size: 12px;
}
</style>
